.contact_section{
    padding: 60px 0px;
}

.titleDiv{
    position: relative;
}

.titleDiv img{
    width: 500px;
}

.title{
    color: #3D3839;
    font-weight: 600;
    max-width: 18rem;
    letter-spacing: 0.5px;
    position: absolute;
    top: 40px;
    left: 60px;
    line-height: normal;
}

.contact_info{
    color: rgba(61, 56, 57, 0.7);
    font-family: 'BRFirma400';
    line-height: 30px;
}

.caption{
    color: #3D3839;
    font-weight: 500;
    text-transform: uppercase;
}

.subtext{
    color: rgba(61, 56, 57, 0.7);
    font-family: 'BRFirma400';
    text-decoration: underline;
    display: block;
}

.subtext:hover{
    color: #F47D20;
}

.input_container label{
    color: rgba(61, 56, 57, 0.7);
    font-size: 14px;
    font-family: 'BRFirma500';
  }
  
  .input_style{
    -webkit-appearance: none;
    appearance: none;
    background: #fff;
    border: 1px solid rgba(244, 125, 32, 0.7);
    border-radius: 6px;
    color: rgba(61, 56, 57, 0.8);
    font-size: 14px;
    padding: 15px 20px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    width: 100%;
    font-family: 'BRFirma500';
  }
  
  .input_style:focus{
     background: white;
      box-shadow: none;
      outline: 0 none;
      border: 1px solid rgba(244, 125, 32, 0.7);
  }
  
  .input_style::placeholder {
    font-size: 14px;
    color: rgba(61, 56, 57, 0.4);
    opacity: 0.5;
  }


  .btn_submit{
    padding: 14px 30px;
    background: #3D3839;
    border: 1px solid #3D3839;
    border-radius: 10px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    font-family: 'Montserrat';
  }
  
  .btn_submit:hover{
    color: #fff;
    background: rgba(61, 56, 57, 0.9)
  }

  .contact_footer{
    color: #3D3839;
    font-size: 14px;
    font-family: 'BRFirma400';
  }

/* ------------------MEDIA QUERY SECTION ------------------------- */
/* Media query for tablets and screens higher */
@media (min-width: 768px) {
}


/* sreens higher than tablets */
@media (min-width: 1024px) {
    .title{
        color: #3D3839;
        font-weight: 600;
        max-width: 18rem;
        letter-spacing: 0.5px;
        position: absolute;
        top: 60px;
        left: 80px;
        line-height: 35px;
    }

}



/* large screens */
@media (min-width: 1536px) {
    
}
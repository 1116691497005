.Card{
    background: #ffffff;
    border-radius: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
}

.firstHalf {
    border-right: none;
    padding: 20px;
    flex: 1;
}

.secondHalf {
    padding: 20px;
    flex: 1;
}

.title{
    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;
}

.subtitle{
    color: rgba(61, 56, 57, 0.7);
    letter-spacing: 0.396px;
    font-family: 'BRFirma400';
    font-size: 14px;
}

.createPin_Sect{
    min-height: auto;
}


.btn_submit{
    color: #fff;
    border-radius: 5px;
    background: #3d3839;
    padding: 14px 30px;
    transition: all 0.15s ease-in-out;
    width: 100%;
    font-weight: 300;
}

.btn_submit:hover{
    color: #fff;
    background: rgba(61, 56, 57, 0.9);
}

.btn_submit.btn_submit_disabled{
  pointer-events: none;
  opacity: 0.65;
}

.managePin_Sect{
    display: flex;
    justify-content: center;
    min-height: 500px;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.caption_sub{
    color: #F47D20;
    font-family: 'BRFirma400';
    font-size: 14px;
    max-width: 22rem;
    margin: auto;
}

.cta_btn{
    color: #F47D20;
    border-radius: 12px;
    background: rgba(244, 125, 32, 0.1);
    padding: 12px 40px;
    transition: all 0.15s ease-in-out;
    font-weight: 500; 
    font-size: 15px;
}

.cta_btn_active{
    background: rgba(61, 56, 57, 0.2);
    color: rgba(61, 56, 57, 0.7);
    pointer-events: none; 
}

.cta_btn:hover{
    color: #F47D20;
}

  
.input_container label {
    color: rgba(61, 56, 57, 0.8);
    font-size: 14px;
    font-family: 'BRFirma500';
  }
  
  .input_style {
    -webkit-appearance: none;
    appearance: none;
    background: rgba(61, 56, 57, 0.02);
    border: 1px solid rgba(244, 125, 32, 0.1);
    border-radius: 6px;
    color: rgba(61, 56, 57, 0.8);
    font-size: 14px;
    padding: 15px 20px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;
    font-family: 'BRFirma500';
  }
  
  
  .input_style:focus {
    background: white;
    box-shadow: none;
    outline: 0 none;
    border: 1px solid rgba(244, 125, 32, 0.1);
  }
  
  .input_style::placeholder {
    font-size: 14px;
    color: rgba(61, 56, 57, 0.8);
    opacity: 0.5;
  }


 /* MEDIA QUERY FOR  TABLETS  */
 @media (min-width: 768px) {

  }
  
  /* MEDIA QUERY FOR LARGE SCREENS AND HIGHER THAN TABLETS  */
  @media (min-width: 1024px) {
      .Card{
        background: #ffffff;
        border-radius: 10px;
        padding-top: 30px;
        padding-bottom: 30px;
        display: flex;
        flex-direction: row;
      }
      .createPin_Sect{
        display: flex;
        flex-direction: column;
        min-height: 400px;
        justify-content: center;  
      }
      .firstHalf {
        border-right: 1px solid #ececec;
        padding: 40px;
        flex: 1;
      }
      .secondHalf {
        padding: 40px;
        flex: 1;
      }
     

  }
  
  /* large screens */
  @media (min-width: 1536px) {
  }
  
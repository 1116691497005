
.userBg{
    background: #FBF7F4;
}

.logo{
   width: 180px;
   margin: auto; 
}


.userside {
    height: 100vh;
    top: 0px;
    transform: translateX(0%);
    width: 255px;
    max-height: calc(100% - 0px);
    background: #fff;
    max-width: 100%;
    overflow-x: hidden;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), z-index 1ms;
    position: fixed;
    border-right: 1px solid #EDF3FA;
}

.userside.show {
    height: 100vh;
    top: 0px;
    transform: translateX(0%);
    width: 255px;
    z-index: 99;
    box-shadow: 0px 3px 6px #00000029;
}

.sidebar-footer-main{
  padding: 0px 20px;
  position: fixed;
  bottom: 30px;
}

.sidebar-footer{
  background: #F4FBF9;
  border: 1px solid #2B8C34;
  border-radius: 10px;
  padding: 8px;
  /* width: 214px; */
}


.sidebar-footer-layout{
  display: flex;
  align-items: center;
  padding: 0 15px;
  margin-bottom: 10px;
  min-height: 50px;
  text-decoration: none !important;
}

.sidebar-footer-layout.active{
  border-left: 3px solid #F47D20;
}

.sidebar-footer-layout:hover {
  background:  #f0f2f5;
}
  

.sidebar-footer-img{
  color: rgb(212, 236, 255, 0.4);
  margin-right: 15px;
  display: inline-flex;
  min-width: 24px;
}

.sidebar-footer-img img{
  width: 22px;
  height: 22px;
}

.sidebar-footer-text span{
  color: #3D3839;
  opacity: 0.6;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.2px;
}

.sidebar-footer-name p{
  color: #1A1A1A;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 70px;
}
.sidebar-footer-name p:last-child{
  color: #4D4D4D;
  font-weight: 500;
  font-size: 12px;
}

.sidebar-footer-icon{
  cursor: pointer;
  margin-left: 40px;
}

.sidehead {
    position: absolute;
    height: 60px;
    top: 0px;
    transform: translateY(0px);
    left: 256px;
    right: 0px;
    z-index: 5;
    padding: 30px 20px 20px 20px;
}

.usermain {
padding: 60px 0px 60px 256px;
}

.v-list {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(80vh - 0px);
    font-family: 'BRFirma400';
}

.v-list-item {
    align-items: center;
    display: flex;
    flex: 1 1 100%;
    letter-spacing: normal;
    min-height: 50px;
    outline: none;
    padding: 0 15px;
    margin-bottom: 10px;
    position: relative;
    text-decoration: none;
}

.v-list-item:hover {
background:  #f0f2f5;
}

.v-list-item:last-child {
margin-bottom: 40px;
}

.v-list-item.active {
    border-left: 3px solid #F47D20;
}

.v-list-item.disabled {
    pointer-events: none;
}

.v-list-icon {
    color: rgb(212, 236, 255, 0.4);
    margin-right: 15px;
    display: inline-flex;
    min-width: 24px;
}

.v-list-icon.active {
    margin-right: 8px;
    color: #dde2ff;
    display: inline-flex;
    min-width: 24px;
}

.v-list-item.disabled{
  pointer-events: none;
}

.v-list-icon img {
    width: 22px;
    height: 22px;
}

.v-list-title.active {
    font-size: 16px;
    flex: 1 1 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 500;
    line-height: 1rem;
    color: #dde2ff;
}

.v-list-title span.active {
    color: #F7931A;
    font-weight: 400;
}

.v-list-title span {
    color:  #3D3839;
    opacity: 0.6;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.2px;
}

.v-list-title span span.notification-count{
    background: rgba(209, 26, 42, 0.1);
    border-radius: 7px;
    color: #D11A2A;
    font-weight: 600;
    padding: 4px 8px;
    margin-left: 10px;
    font-size: 12px;
}

.bell_div{
  background: #FEFEFF;
  box-shadow: 0px 100px 80px rgba(244, 125, 32, 0.05), 0px 64.8148px 46.8519px rgba(244, 125, 32, 0.038), 0px 38.5185px 25.4815px rgba(244, 125, 32, 0.03), 0px 20px 13px rgba(244, 125, 32, 0.025), 0px 8.14815px 6.51852px rgba(244, 125, 32, 0.0196), 0px 1.85185px 3.14815px rgba(244, 125, 32, 0.012);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 40px;
  height: 40px;
}

nav img.imageStyle {
    cursor: pointer;
    width: 22px;
    height: 22px;
}

.nav-right{
  display: flex;
  align-items: center;
}

.user_p{
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 10px;
}

.titlehead{
    color: #3D3839;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 0px;
}

.back_arrow_div{
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.back_arrow_icon{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #E1EFE2;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 10px;
    cursor: pointer;
}

.back_arrow_div span{
    color: #666666;
    font-size: 14px;
    font-weight: 500;
}

.btn_edit{
    padding: 14px 22px;
    background: transparent;
    border: 1px solid #2B8C34;
    border-radius: 5px;
    color: #2B8C34;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    width: 100%;
}

.btn_edit:hover{
    color: #2B8C34;
    border: 1px solid #2B8C34;
}

::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
    border-radius: 0 0 5px 0;
}
::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color:#cccccc;
}

.sidebar-footer-div{

}

.sidebar-user-icon{
    width: 45px;
    height: 45px;
    border-radius: 50%;
}

.sidebar-logout{
    cursor: pointer;
}


/* ------------------MEDIA QUERY SECTION ------------------------- */
/* Media query for tablets and screens higher */
@media (min-width: 768px) {
.side-logo {
  position: absolute;
  bottom: 50px;
  transform: rotate(-90.04deg);
  right: -50px;
}
.side-logo img {
  opacity: 0.1;
  height: 80px;
}

.sidebar-footer-icon{
    cursor: pointer;
    margin-left: 45px;
}

.titlehead{
    color: #3D3839;
    font-weight: 500;
    border-bottom: 1px solid #F47D20;
    padding: 0px 0px 5px 0px;
}

.back_arrow_div{
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}  

}

/* large screens */
@media (min-width: 1536px) {

.v-list {
  padding: 0px 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(80vh - 0px);
}
.sidebar-footer-main{
  padding: 0px 20px;
  position: fixed;
  bottom: 50px;
}
}

/* Media query for smaller phones */
@media (max-width: 767px) {
.sidehead {
  position: absolute;
  height: 60px;
  margin-top: 0px;
  transform: translateY(0px);
  left: 0px;
  right: 0px;
  padding: 20px 25px;
}
.userside {
  height: 100vh;
  top: 0px;
  transform: translateX(-100%);
  width: 255px;
  z-index: 99;
}
.usermain {
  padding: 60px 0px 60px;
}
.side-logo {
  position: absolute;
  bottom: 150px;
  transform: rotate(-90.04deg);
  right: -40px;
}
.side-logo img {
  opacity: 0.1;
  height: 80px;
}
}

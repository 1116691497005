/* ------------------------------- LOGIN PAGE STYLES STARTS HERE ------------------------------- */

.onboarding_img {
  height: 100%;
  max-width: 100%;
  min-height: 100vh;
}

.Auth_div {
  background: #fbf7f4;
  min-height: 100vh;
  height: 100%;
  font-family: 'BRFirma400';
  position: relative;
}

.auth_right_sect {
  padding: 80px 25px;
}

.login_header {
  text-align: center;
}

.login_header p {
  /* font-size: 18px; */
  color: rgba(61, 56, 57, 0.7);
}

.input_container label {
  color: rgba(61, 56, 57, 0.8);
  font-size: 14px;
  font-family: 'BRFirma500';
}


.resetexpiredtext{
   font-style: normal;
   font-weight: 300;
    line-height: 150%;
    font-size: 16px;
    color: rgba(61, 56, 57, 0.7);
    margin: auto;
    max-width: 40rem;
}

.login_link{
  color: rgba(61, 56, 57, 0.7);
  cursor: pointer;
  text-align: center;
  font-size: 14px;
}

.login_link a{
  color: #f47d20;
  text-decoration: none;
}

.input_style {
  -webkit-appearance: none;
  appearance: none;
  background: #ffffff;
  border: none;
  border-radius: 6px;
  color: rgba(61, 56, 57, 0.8);
  font-size: 14px;
  padding: 15px 20px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
  font-family: 'BRFirma500';
}

.input_style:focus {
  background: white;
  box-shadow: none;
  outline: 0 none;
  border: 1px solid rgba(61, 56, 57, 0.4);
}

.input_style::placeholder {
  font-size: 14px;
  color: rgba(61, 56, 57, 0.4);
  opacity: 0.5;
}

.auth_icon_email {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  width: 20px;
  height: 20px;
}

.auth_icon_password {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  width: 20px;
  height: 20px;
}

.forgot_psw {
  font-size: 14px;
  color: rgba(61, 56, 57, 0.7);
  opacity: 0.7;
  text-decoration: none;
}

.forgot_psw:hover {
  text-decoration: none;
  color: rgba(61, 56, 57, 0.7);
  opacity: 0.7;
}

.form_div_signup {
  color: rgba(61, 56, 57, 0.7);
  cursor: pointer;
  text-align: center;
  font-size: 14px;
}

.form_div_signup a {
  color: #f47d20;
  text-decoration: none;
  font-size: 14px;
}

.footer img {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

/* ------------------------------- LOGIN PAGE STYLES ENDS HERE ------------------------------- */

/* ------------------------------- SIGNUP PAGE STYLES STARTS HERE ------------------------------- */
.Auth_signup_div {
  background: #fbf7f4;
  height: 100%;
  font-family: 'BRFirma400';
}

.signup {
  font-weight: 600;
  color: #3d3839;
  font-family: 'Montserrat';
}

.signup_title {
  color: rgba(61, 56, 57, 0.7);
  font-family: 'BRFirma400';
  line-height: 160%;
}

.form_div_signin {
  font-size: 18px;
  color: rgba(61, 56, 57, 0.7);
  padding-top: 10px;
  cursor: pointer;
  text-align: center;
  font-size: 15px;
  color: rgba(61, 56, 57, 0.7);
}

/* ------------------------------- SIGNUP PAGE STYLES ENDS HERE ------------------------------- */




/*----------------------------------------------- MEDIA QUERY SECTION-------------------------------------------------- */

/* MEDIA QUERY FOR SMALL SCREENS AND TABLETS */
@media (min-width: 280px) {
  /* I used min-width of 280px instead of 320px because of phones that have smaller width like galaxy fold */

  /* ------------------------------- LOGIN PAGE STYLES STARTS HERE ------------------------------- */

  .form_div_signup {
    color: rgba(61, 56, 57, 0.7);
    cursor: pointer;
    text-align: center;
    color: rgba(61, 56, 57, 0.7);
  }

  /* ------------------------------- LOGIN PAGE STYLES ENDS HERE ------------------------------- */
  .main_form_div {
    margin-bottom: 25%;
  }

  .reset_password {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    color: #3d3839;
  }

  .reset_password_ {
    font-style: normal;
    font-weight: 300;
    line-height: 130%;
    color: rgba(61, 56, 57, 0.7);
    width: 100%;
  }

  .change_password {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    color: #3d3839;
    padding-top: 4px;
  }

  .changepsw_footer img {
    z-index: -1;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 2%;
  }
  /* -----------------------------------------------VERIFY  ACCOUNT STYLES------------------------------------------------- */
  
  .verify_body{
    background: #FBF7F4;
  }


  .verify_icon_container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .verify_icon_container img {
    width: 66px;
    height: 66px;
  }

  .verifyacct_p {
    font-weight: 300;
    color: #3d3839;
    font-family: 'Montserrat';
  }

  .linksent_p {
    max-width: 22rem;
    margin: auto;
    color: rgba(61, 56, 57, 0.7);
    font-family: 'BRFirma400';
  }

  .sendagain_p {
    color: rgba(61, 56, 57, 0.7);
    font-family: 'BRFirma400';
    padding-top: 15px;
    cursor: pointer;
  }

  .link_status{
    color: #f47d20;
    text-decoration: none;
    cursor: pointer;
  }

  .link_disabled{
    opacity: 0.65;
    pointer-events: none;
  }

  

  /* -------------- VERIFY ACCOUNT ---------- */

  .verify_container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    text-align: center;
  }

  .verify_container span {
    color: #f47d20;
    text-decoration: none;
    cursor: pointer;
  }

  .chevron_icon {
    color: #3d3839b2;
    /* font-size: 12px; */
  }

  .verifiedlink_div{
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }

  .verifiedlink_div:hover{
    text-decoration: none;
  }

  .verifystatus_link{
    color: #f47d20;
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 0.396px;
    font-family: 'BRFirma400';
    margin-bottom: 0px;
    margin-right: 10px;
  }
}

/* MEDIA QUERY FOR  TABLETS  */

@media (min-width: 768px) {
  .main_form_div {
    margin-bottom: 5%;
  }

  /* -----------------------------------------------VERIFY  ACCOUNT STYLES------------------------------------------------- */
  .verify_icon_container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .verify_icon_container img {
    width: 106px;
    height: 106px;
  }

  .verifyacct_p {
    color: #3d3839;
    font-family: 'Montserrat';
    font-weight: 300;
  }

  .linksent_p {
    max-width: 22rem;
    margin: auto;
    color: rgba(61, 56, 57, 0.7);
    font-family: 'BRFirma400';
  }

  .sendagain_p {
    color: rgba(61, 56, 57, 0.7);
    font-family: 'BRFirma400';
    padding-top: 29px;
    cursor: pointer;
  }

  .verify_container span {
    color: #f47d20;
    text-decoration: none;
    cursor: pointer;
  }

  .chevron_icon {
    color: #3d3839b2;
    /* font-size: 20px; */
  }
}

/* MEDIA QUERY FOR LARGE SCREENS AND HIGHER THAN TABLETS  */
@media (min-width: 1024px) {
  /* ------------------------------- LOGIN PAGE STYLES STARTS HERE ------------------------------- */
  .auth_right_sect {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    min-height: 100vh;
    padding: 50px;
  }

  .form_div {
    width: 100%;
    max-width: 30rem;
    margin: auto;
  }

  /* ------------------------------- LOGIN PAGE STYLES ENDS HERE ------------------------------- */
  .main_form_div {
    margin-bottom: 3%;
  }

  .change_password {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    color: #3d3839;
    padding-top: 20px;
  }

  .reset_password {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    color: #3d3839;
  }

  .reset_password_ {
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    font-size: 16px;
    color: rgba(61, 56, 57, 0.7);
    width: 457px;
  }

  .changepsw_footer img {
    z-index: 1;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 2%;
  }

  /* -----------------------------------------------VERIFY  ACCOUNT STYLES------------------------------------------------- */
 
  .verify_icon_container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .verifyacct_p {
    color: #3d3839;
    font-family: 'Montserrat';
  }

  .linksent_p {
    max-width: 22rem;
    margin: auto;
    color: rgba(61, 56, 57, 0.7);
    font-family: 'BRFirma400';
  }

  .verify_icon_container img {
    width: 126px;
    height: 126px;
  }

  .sendagain_p {
    font-size: 14px;
    color: rgba(61, 56, 57, 0.7);
    font-family: 'BRFirma400';
    padding-top: 20px;
    cursor: pointer;
  }

  .verify_container span {
    color: #f47d20;
    text-decoration: none;
    cursor: pointer;
  }

  .chevron_icon {
    color: #3d3839b2;
    /* font-size: 20px !important; */
  }
}

/* ---------------------- NavBar stylesheet ------------------------------ */
.nav_header {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    position: relative;
    z-index: 99;
  }

.scrolled {
    position: sticky;
    top: 0px;
    right: 0px;
    left: 0px;
    z-index: 100;
    background-color: rgba(255, 255, 255, 1);
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
    transition-duration: 200ms;
    transition-timing-function: linear;
     box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
}

nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }


.nav_links_head{
    display: none;
}

.nav_links_head li a {
    display: inline-block;
    text-decoration: none;
    font-size: 14px;
    color: rgba(61, 56, 57, 0.8);
    font-family: 'BRFirma400';
    line-height: 15px;
    font-weight: 400;
    margin: 0 25px;
    position: relative;
}

.nav_links_head li a.register_cta{
    border: 1px solid #F47D20;
    border-radius: 5px;
    color: #F47D20;
    background: transparent;
    padding: 14px 0px;
    min-width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-family: 'Montserrat';
    margin: 0 30px 0px 0px;
    transition: all .15s ease-in;
}

.nav_links_head li a.login_cta{
    border: 1px solid #3D3839;
    border-radius: 5px;
    color: #fff;
    background: #3D3839;
    padding: 14px 0px;
    min-width: 130px;
    display: flex;
    justify-content: center;
    font-weight: 600;
    font-family: 'Montserrat';
    margin: 0;
    transition: all .15s ease-in;
}

.nav_links_head li a.register_cta:hover{
    background: #3D3839;
    color: #fff;
    border: 1px solid #3D3839;
}

.nav_links_head li a.login_cta:hover{
    background: #F47D20;
    color: #fff;
    border: 1px solid #F47D20;
}

.nav_links_head li a.register_cta:hover:before,.nav_links_head li a.login_cta:hover:before{
    display: none;
}

.nav_links_head li a.register_cta:before,.nav_links_head li a.login_cta:before{
    display: none;
}

.nav_links_head li a.activeLink{
    font-family: 'BRFirma600';
    color: #3D3839;
}

  .nav_links_head li a:hover {
    color: #3D3839;
    text-decoration: none;
  }
  
  .nav_links_head li a:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  
  .nav_links_head li a:before {
    position: absolute;
    bottom: -8px;
    left: 0;
    visibility: hidden;
    width: 100%;
    height: 2px;
    content: "";
    transition: all 0.3s ease-in-out 0s;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    background-color: #F47D20;
  }


.open_icon {
    width: 1.5rem;
    height: 1.5rem;
    color: #F47D20;
    cursor: pointer;
}

.mobile_menu {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    padding: 0.5rem;
    transition-property: background-color, border-color;
    transition-duration: 150ms;
    transform-origin: top right;
    transform: translateX(0) translateY(0) rotate(0) skewX(0) skewY(0) scaleX(1)
      scaleY(1);
}

.mobile_menu_div {
    border-radius: 0.5rem;
    background: white;
    overflow: hidden;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.mobile_container {
    padding-top: 1rem;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .mobile_links {
    padding-top: 1rem;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
    padding-bottom: 0.75rem;
  }
  
  .mobile_links a {
    display: block;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: rgba(61, 56, 57, 0.8);
    font-family: 'BRFirma400';
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 0.375rem;
    text-decoration: none;
  }
  
  .mobile_links a:hover {
    background-color: rgba(249, 250, 251, 1);
    color: #3D3839;
}

.mobile_link_cta{
    background: #f9fafb;
    border-radius: 0.5rem;
    display: block;
    color: #F47D20;
    font-size: 14px;
    line-height: 15px;
    padding: 1rem 1.25rem;
    text-align: center;
    width: 100%;
    font-family: 'Montserrat';
    font-weight: 600;
}

/* ------------- NAVBAR SECTION ---------------- */

/* -------------- HERO SECTION ---------------- */
.section_hero{
  padding: 40px 0px;
}

.hero_title{
  color: #3D3839;
  font-weight: 600;
  /* line-height: 50px; */
  letter-spacing: 0.396px;
  max-width: 28rem;
}

.hero_subtitle{
  letter-spacing: 0.396px;
  font-family: 'BRFirma400';
  color: rgba(61, 56, 57, 0.8);
  max-width: 24rem;
}

.hero_cta{
  display: flex;
}

.btnRegister{
  background: transparent;
  border: 1px solid #FF8400;
  border-radius: 5px;
  color: #F47D20;
  font-weight: 600;
  padding: 13px 30px;
  transition: all .15s ease-in-out;
  margin-right: 20px;
  min-width: auto;
}

.btnRegister:hover{
  background: #F47D20;
  color: #fff;
}

.btnSignIn{
  background: #3D3839;
  border: 1px solid #3D3839;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
  font-family: 'Montserrat';
  padding: 13px 30px;
  min-width: auto;
  transition: all .15s ease-in-out;
}

.btnSignIn:hover{
  background: #F47D20;
  color: #fff;
  border: 1px solid #F47D20;
}

.heroImgSect{
  position: relative;
}

.heroImg{
  /* z-index: 3; */
}

.line1{
 display: none;
}

.line2{
 display: none;
}

.box{
 display: none;
}

.box_layout_div{
  position: absolute;
  left: -40px;
  bottom: 10px;
  display: none;
}

.box_lay{
  background: #FFFFFF;
  box-shadow: 0px 12px 32px rgba(63, 128, 240, 0.08);
  border-radius: 8px;
  padding: 15px 20px;
  display: flex;
}

.box_cirlce{
  margin-right: 15px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(96.14deg, #F0A062 -10.84%, #FBA25E 196.74%), linear-gradient(145.08deg, rgba(244, 125, 32, 0.88) 3.73%, rgba(244, 124, 32, 0.72) 132.69%);
  box-shadow: 0px 4px 19px rgba(244, 142, 63, 0.2), inset 0px 4px 4px rgba(61, 56, 57, 0.05);
 }

 .box_name{
  color: #3D3839;
  font-weight: 500;
  margin-bottom: 0px;
 }

 .box_subtext{
  color: rgba(61, 56, 57, 0.7);
  font-family: 'BRFirma400';
  font-size: 14px;
  margin-bottom: 0px;
 }



 /* ------------- CARDS SECTION ------------ */
 .cards_section{
  background: rgba(255, 245, 236, 0.7);
  padding: 25px 0px;
 }

.card_div{
  text-align: center;
  margin-right: 30px;
  flex-shrink: 0;
}

 .cards_layout{
   display: flex;
   justify-content: space-between;
   overflow-x: scroll;
 }


.cards_layout::-webkit-scrollbar {
  display: none;
 }

 .card_title{
  color: rgba(61, 56, 57, 0.7);
  font-size: 14px;
  font-family: 'BRFirma400';
  margin-bottom: 0px;
 }


 /*  ---- STEPS SECTION ----------- */
 .steps_section{
   padding: 40px 0px;
 }

 .steps_title{
  letter-spacing: 0.5px;
  color: #3D3839;
  font-weight: 600;
 }

 .steps_card{
  background: #FFFFFF;
  box-shadow: 0px 8px 25px rgba(209, 177, 153, 0.2);
  border-radius: 10px;
  padding: 30px 20px 40px 20px;
 }

 .steps_circle{
  background: #FDE2CD;
  box-shadow: 0px 4px 19px rgba(244, 142, 63, 0.2);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
 }

 .steps_circle span{
  color: #F47D20;
  letter-spacing: 0.2px;
  font-weight: 700;
  font-size: 25px;
 }

 .steps_text{
  color: rgba(61, 56, 57, 0.9);
  font-weight: 600;
 }

 .steps_caption{
  color: rgba(61, 56, 57, 0.7);
  font-family: 'BRFirma400';
  font-size: 14px;
  margin-bottom: 0px;
 }



.step_layout_div{
  position: absolute;
  top: 150px;
  left: -100px;
  display: none;
}

.step_lay{
  background: #FFFFFF;
  box-shadow: 0px 12px 32px rgba(63, 128, 240, 0.08);
  border-radius: 8px;
  padding: 15px 20px;
  display: flex;
}

.step_cirlce{
  margin-right: 15px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(96.14deg, #F0A062 -10.84%, #FBA25E 196.74%), linear-gradient(145.08deg, rgba(244, 125, 32, 0.88) 3.73%, rgba(244, 124, 32, 0.72) 132.69%);
  box-shadow: 0px 4px 19px rgba(244, 142, 63, 0.2), inset 0px 4px 4px rgba(61, 56, 57, 0.05);
 }

 .step_name{
  color: #3D3839;
  font-weight: 500;
  margin-bottom: 0px;
 }

 .step_subtitle{
  color: rgba(61, 56, 57, 0.7);
  font-family: 'BRFirma400';
  font-size: 14px;
  max-width: 10rem;
  margin-bottom: 0px;
 }


 /* ----------- INFO SECTION ------------- */
 .info_section{
  background: #FEF6EE;
  padding: 40px 0px; 
 }

 .info_title{
  letter-spacing: 0.396px;
  color: #3D3839;
  font-weight: 600;
  max-width: 28rem;
 }

 .info_subtext{
  letter-spacing: 0.396px;
  color: rgba(61, 56, 57, 0.7);
  font-size: 14px;
  max-width: 24rem;
 }

 .info_cta{
  background: #3D3839;
  border: 1px solid #3D3839;
  border-radius: 5px;
  color: #fff;
  font-weight: 600;
  padding: 13px 30px;
  transition: all .15s ease-in-out;
 }

 .info_cta:hover{
  background: rgba(61, 56, 57, 0.9);
  color: #fff;
 }


.info_layout_div{
  position: absolute;
  top: 150px;
  left: -140px;
  display: none;
}

.info_lay{
  background: #FFFFFF;
  box-shadow: 0px 12px 32px rgba(63, 128, 240, 0.08);
  border-radius: 8px;
  padding: 15px 20px;
  display: flex;
}

.info_cirlce{
  margin-right: 15px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(96.14deg, #F0A062 -10.84%, #FBA25E 196.74%), linear-gradient(145.08deg, rgba(244, 125, 32, 0.88) 3.73%, rgba(244, 124, 32, 0.72) 132.69%);
  box-shadow: 0px 4px 19px rgba(244, 142, 63, 0.2), inset 0px 4px 4px rgba(61, 56, 57, 0.05);
 }

 .info_name{
  color: #3D3839;
  font-weight: 500;
  margin-bottom: 0px;
 }

 .info_subtitle{
  color: rgba(61, 56, 57, 0.7);
  font-family: 'BRFirma400';
  font-size: 14px;
  margin-bottom: 0px;
 }


 /* ---- FEATURES SECTION ------------ */
 .features_sect{
  background: #FEF6EE;
  padding: 40px 0px; 
 }

 .feat_title{
  letter-spacing: 0.5px;
  color: #3D3839;
  font-weight: 600;
  max-width: 20rem;
  margin: auto;
 }

 .features_div{
   display: flex;
 }

 .features_icon_div{
  background: #FDE2CD;
  border-radius: 10px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  flex-shrink: 0;
  margin-right: 30px;
 }

 .features_title{
  color: rgba(61, 56, 57, 0.8);
  font-weight: 600;
 }
 .features_subtext{
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  letter-spacing: 0.396px;
  max-width: 28rem;
 }

 /* =------ TESTIMONIALS SECTION ------------ */
 .testimonials_section{
   padding: 40px 0px 60px 0px;
 }

 .test_title{
  letter-spacing: 0.5px;
  color: #3D3839;
  font-weight: 600;
  max-width: 24rem;
  margin: auto; 
 }


 .testimonial_card{
  background: #FFFFFF;
  box-shadow: 0px 18px 52.8537px rgba(209, 177, 153, 0.2);
  border-radius: 10px;
  padding: 40px 40px;
  max-width: 30rem;
  margin: auto;
  position: relative;
  margin-top: 50px;
 }

 .testimonial_text{
  color: rgba(61, 56, 57, 0.7);
  font-family: 'BRFirma400';
  text-align: center;
  line-height: 28px;
 }

 .client1{
   display: none;
 }

 .client2{
  display: none;
 }

 .client3{
  display: none;
 }

 .client4{
  display: none;
 }
 .client5{
  display: none;
 }

 .client6{
  display: none;
 }

/* ---------------- Footer ---------*/
.section_footer{
    background: #3D3839;
    padding: 40px 0px;
}

.footer_link_title{
    color: #FAFBFE;
  }

  .footer_info{
    color: #FFFFFF;
    font-family: 'BRFirma400';
    font-size: 14px; 
    max-width: 24rem;
  }

  .social_icons{
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .social_div{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 20px;
  }
  
  .social_div img{
    width: 25px;
    height: 25x;
  }

  .social_div:last-child{
    margin-right: 0px;
  }
  
  
  .footer_links{
    list-style: none;
  }
  
  .footer_links li a{
    color: #FAFBFE;
    text-decoration: none;
    font-size: 14px;
    font-family: 'BRFirma400';
    transition: all .15s ease-in-out;
  }

  .footer_links li a.contact{
      color: rgba(255, 255, 255, 0.8)
  }
  
  .footer_links li a:hover{
    color: #F47D20;
  }

  .footer_hr{
      margin-top: 40px;
      border-top: 1px solid rgba(255, 255, 255, 0.6);
  }

  .footer_copyright{
    color: rgba(255, 255, 255, 0.8);
    font-family: 'BRFirma400';
    font-size: 14px;
  }



  /* ------ FOOTER SECTION --------------- */
  



/* ------------------MEDIA QUERY SECTION ------------------------- */
/* Media query for tablets and screens higher */
@media (min-width: 768px) {
 /* ----------- Navbar  styles ------ */
  
/*  --- HERO SECTION ------ */
.section_hero{
  padding: 60px 0px;
}

.btnRegister, .btnSignIn{
  min-width: 140px;
}



.line1{
  position: absolute;
  top: 5px; 
  left: 80px;
  display: block;
}

.line2{
  position: absolute;
  right: 0px;
  top: -40px;
  z-index: -1;
  display: block;
}

.box{
  position: absolute;
  bottom: 30px;
  right: 80px;
  z-index: -1;
  display: block;
}

.box_layout_div{
  position: absolute;
  left: -40px;
  bottom: 10px;
  display: block;
}

.box_lay{
  background: #FFFFFF;
  box-shadow: 0px 12px 32px rgba(63, 128, 240, 0.08);
  border-radius: 8px;
  padding: 15px 20px;
  display: flex;
}

.box_cirlce{
  margin-right: 15px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(96.14deg, #F0A062 -10.84%, #FBA25E 196.74%), linear-gradient(145.08deg, rgba(244, 125, 32, 0.88) 3.73%, rgba(244, 124, 32, 0.72) 132.69%);
  box-shadow: 0px 4px 19px rgba(244, 142, 63, 0.2), inset 0px 4px 4px rgba(61, 56, 57, 0.05);
 }

 .box_name{
  color: #3D3839;
  font-weight: 500;
  margin-bottom: 0px;
 }

 .box_subtext{
  color: rgba(61, 56, 57, 0.7);
  font-family: 'BRFirma400';
  font-size: 14px;
  margin-bottom: 0px;
 }

 /* ----- CARDS SECTION */
 .cards_section{
  background: rgba(255, 245, 236, 0.7);
  padding: 40px 0px;
 }

 .cards_layout{
  display: flex;
  justify-content: space-between;
}

.card_div{
  text-align: center;
  flex-shrink: 0;
  margin-right: 0px;
}

/* ---- STEPS SECTION -------- */
.steps_section{
  padding: 60px 0px;
}

.step_layout_div{
  position: absolute;
  top: 150px;
  left: -90px;
  display: block;
}


/*  ----- INFO SECTION ------ */
.info_section{
  background: #FEF6EE;
  padding: 60px 0px; 
 }

.info_layout_div{
  position: absolute;
  top: 150px;
  left: -140px;
  display: block;
}

/*  --- FEATURES SECTION ---------- */
.features_sect{
  background: #FEF6EE;
  padding: 60px 0px; 
 }

/* ----- TESTIMONIAL SECTION --------- */
.testimonials_section{
  padding: 60px 0px 150px 0px;
}

.testimonial_card{
  margin-top: 100px;
}

.client1{
  position: absolute;
  top: -90px;
  left: -30px;
  display: block;
}

.client2{
  position: absolute;
  right: -50px;
  top: -100px;
  display: block;
}

.client3{
  position: absolute;
  right: -150px;
  top: 60px;
  display: block;
}

.client4{
  position: absolute;
  left: -180px;
  top: 40px;
  display: block;
}
.client5{
  position: absolute;
  bottom: -50px;
  left: -100px;
  display: block;
}

.client6{
  position: absolute;
  bottom: -90px;
  right: -70px;
  display: block;
}

/* Footer styles */
.footer_hr{
    margin-top: 60px;
    border-top: 1px solid rgba(255, 255, 255, 0.6);
}

}


/* sreens higher than tablets */
@media (min-width: 1024px) {

  /* --- NAVBAR STYLES ------- */
  .nav_links_head {
    display: flex;
    align-items: center;
    width: auto;
    list-style: none;
    margin-top: 10px;
}


}


/* large screens */
@media (min-width: 1536px) {
}
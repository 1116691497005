

.tradeDiv{
    background: #fff;
    border-radius: 12px;
    padding: 10px;
}

.trade_filter_container{
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
}

.transaction_search{
    display: flex;
}

.search_container{
    width: 100%;
}

.input_container label {
    color: rgba(61, 56, 57, 0.8);
    font-size: 14px;
    font-family: 'BRFirma500';
  }


.search_input_style{
    border: 1px solid rgba(244, 125, 32, 0.2);
    border-radius: 5px;
    font-size: 14px;
    color: rgba(61, 56, 57, 0.8);
    width: 100%;
    padding: 14px 20px;
    background: #fff;
    appearance: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.search_input_style:hover{
    border: 1px solid rgba(244, 125, 32, 0.2);
    background: white;
    box-shadow: none;
    outline: 0 none;  
}

.input_style{
    border: 1px solid rgba(244, 125, 32, 0.1);
    border-radius: 5px;
    font-size: 14px;
    color: rgba(61, 56, 57, 0.8);
    width: 100%;
    padding: 14px 20px;
    background: #FBFBFB;
    appearance: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.input_style:focus{
    border: 1px solid rgba(244, 125, 32, 0.1);
    background: white;
    box-shadow: none;
    outline: 0 none;
  }
  
.input_style::placeholder{
    color:rgba(26, 55, 78, 0.5);
    font-size: 14px;
    font-family: 'BRFirma400';
}

.filter_div{
    border: 1px solid rgba(61, 56, 57, 0.7);
    border-radius: 5px;
     padding: 14px 40px;
     color: rgba(61, 56, 57, 0.7);
     transition: all 0.15s ease-in-out;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
}


.filter_input_style{
    border: 1px solid rgba(244, 125, 32, 0.1);
    border-radius: 5px;
    font-size: 14px;
    color: rgba(61, 56, 57, 0.8);
    width: 100%;
    padding: 14px 20px;
    background: #FBFBFB;
    appearance: none;
    cursor: pointer;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.filter_input_style:focus{
    border: 1px solid rgba(244, 125, 32, 0.1);
    background: white;
    box-shadow: none;
    outline: 0 none;
  }
  
.filter_input_style::placeholder{
    color:rgba(26, 55, 78, 0.5);
    font-size: 14px;
    font-family: 'BRFirma400';
  }

.btn_filter{
    color: #fff;
    border-radius: 6px;
    background: #3D3839;
    padding: 14px 30px;
    transition: all 0.15s ease-in-out;
    width: 100%;
    font-weight: 300;
}

.btn_filter:hover{
    color: #fff;
    background: rgba(61, 56, 57, 0.9);
}

.tradeCard{
    background: #FFFFFF;
    border-radius: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
}

.firstHalf{
    border-right: none;
    padding: 20px;
    flex: 1;
}

.secondHalf{
    padding: 20px;
    flex: 1;
}

.cardTitle{
    color: rgba(61, 56, 57, 0.7);
    font-weight: 500;
}



.cursor_pointer {
    cursor: pointer;
}

.input_group{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.input_group>.custom_file {
    display: -ms-flexbox;
    display: flex;
    /* border: 1px solid rgba(8, 152, 215, 0.2); */
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}

.custom_file, .custom_file_input {
    height: calc(49px + 2px);
    cursor: pointer;
}

.custom_file_input {
    height: calc(49px + 2px);
    cursor: pointer;
}

.custom_file_input {
    position: relative;
    z-index: 2;
    width: 100%;
    margin: 0;
    opacity: 0;
}

.custom_file_label {
    padding: 14px 80px 14px 18px;
    height: calc(49px + 2px);
}

.custom_file_label {
    position: absolute;
    /* top: 0; */
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    left: 0px;
    z-index: 1;
    line-height: 1.5;
    color: #1A374E;
    background: #FBFBFB;
    border: 1px solid rgba(244, 125, 32, 0.1);
    border-radius: .25rem;
    cursor: pointer;
}

.custom_file_label::after {
    position: absolute;
    /* top: 5px; */
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    z-index: 3;
    display: block;
    content: "Browse";
    background: transparent;
    border-left: none;
    border-radius: 0 .25rem .25rem 0;
    color: rgba(61, 56, 57, 0.8);
    font-weight: 500;
}

.custom_file_input:focus {
    border: none;
    box-shadow: none;
    outline: 0 none;
}

.fileupload{
    margin-top: 10px;
    position: relative;
}
.imageFile{
    position: relative;
    width: fit-content;
    /* margin: 0 15px 15px 0; */
}
.imageFile .close_icon {
    position: absolute;
    top: 0px;
    right: -6px;
    cursor: pointer;
    z-index: 1;
}
.close_icon {
    width: 22px;
    height: 22px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAAAXNSR0IArs4c6QAAAhtJREFUOBG1lc9LAlEQx2clDUUhA1ESAiHRi0H37L6esvVPCfobhI52tj+g7cfJztk93JNiIASmsqCBoaTJNt/VlbXeVhbOYd9j3sznzZs3b1YiB2k0Ghv9fj/Ly4okSbuGYYRgynOd5xpP1UAgcBWLxV6g/yzSZ0W9Xl8fDAbHDDjx+/1SMBj0e71ecrvdpul4PKbhcEi9Xu+Vhfcw8j6f7zQej7/ZWQvgarW6NRqNSgzciUajPo/HY7f9MmdbajabA97gkW3lZDL5bBnNwTPoQyQS2QyFQmuWwW9GXdff2+12l+F7FtwFRxwfkf4FCn8EAl8wwILOBCOnOP6ykQJgCXzBAAs6aXb7T4lEIvBTTi2I04ic12q1PlfLtgslhdv/LxSbgQEWmEiFgpJyiqJYLFKn01lY5ouic9aLZMZSXCh+1KmTyLJM6sXFHA7opaqSnMkIXcACkzRNG04mExS6o7RaLeOsUDAqlYo5MtzRFiwwzaoQbm1TcinRfjpNN9fXlD44oHA4bFsVT5EKHc/0O8Hx78tlOsxmqXx3N0+LyAcsMF18Jg1v30msnCq5HKVSKcKInEMvErDARCpUNBSREXS3pZIJs46P8UhRTL3IZ8ZSV/dA0E859Dy6lCiCZXRggAWmWRXop2h96FLLgOy28AUDLOhNMJo0P0eZL6T7F7itbcpWw5/3Y+yykkYPMGQlv6Ypevr978/0AyH3oBomU94UAAAAAElFTkSuQmCC) no-repeat;
}


.cardImage{
    /* border-radius: 10px; */
    height: 72px;
    max-width: 100%;
}


.info_title{
    color: rgba(61, 56, 57, 0.7);
    font-weight: 600;
    font-size: 15px;
}

.info_subtitle{
    color: rgba(61, 56, 57, 0.7);
    font-family: 'BRFirma400';
    font-size: 14px;
}

.pending_badge{
    color: #FFD66B;
    background: rgba(255, 214, 107, 0.1);
    border-radius: 100px;
    padding: 5px 20px;
}

.success_badge{
    color: #9CC031;
    background: rgba(156, 192, 49, 0.1);
    border-radius: 100px;
    padding: 5px 20px;   
}

.failed_badge{
    color: #FF8F6B;
    background: rgba(255, 143, 107, 0.1);
    border-radius: 100px;
    padding: 5px 20px; 
}


.btn_start{
    color: #fff;
    border-radius: 6px;
    background: #3D3839;
    padding: 14px 30px;
    transition: all 0.15s ease-in-out;
    width: 100%;
    font-weight: 300;
}

.btn_start:hover{
    color: #fff;
    background: rgba(61, 56, 57, 0.9);
}



/* MEDIA QUERY FOR  TABLETS  */
@media (min-width: 768px) {

}



/* MEDIA QUERY FOR LARGE SCREENS AND HIGHER THAN TABLETS  */
@media (min-width: 1024px) {
    .trade_filter_container{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding: 25px 20px 0 0;
    }
    .search_input_style{
        width: 500px;
    }
    .tradeCard{
        background: #FFFFFF;
        border-radius: 10px;
        padding-top: 30px;
        padding-bottom: 30px;
        display: flex;
        flex-direction: row;
    }
    .firstHalf{
        border-right: 1px solid #ECECEC;
        padding: 40px;
        flex: 1;
    }

    .secondHalf{
        padding: 40px;
        flex: 1;
    }

}


/* large screens */
@media (min-width: 1536px) {
    
}
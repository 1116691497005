.policy_section {
  padding: 60px 0px;
}

.titleDiv {
  position: relative;
}

.titleDiv img {
  width: 500px;
}

.title {
  color: #3d3839;
  font-weight: 600;
  max-width: 18rem;
  letter-spacing: 0.5px;
  position: absolute;
  top: 30px;
  left: 60px;
  line-height: normal;
}

.titleP {
  color: #3d3839;
  font-weight: 600;
  max-width: 18rem;
  letter-spacing: 0.5px;
  position: absolute;
  top: 40px;
  left: 60px;
  line-height: normal;
}

.terms_content {
}

.terms_content h6 {
  font-weight: 600;
}

.terms_content p {
  font-family: 'BRFirma400';
  color: rgba(61, 56, 57, 0.7);
}

.terms_content li {
  font-family: 'BRFirma400';
  color: rgba(61, 56, 57, 0.7);
}

.terms_content a {
  color: #f47d20;
  text-decoration: none;
}

.terms_content a:hover {
  color: #f47d20;
  text-decoration: none;
}

/* MEDIA QUERY FOR  TABLETS  */
@media (min-width: 768px) {
}

/* MEDIA QUERY FOR LARGE SCREENS AND HIGHER THAN TABLETS  */
@media (min-width: 1024px) {
  .title {
    color: #3d3839;
    font-weight: 600;
    max-width: 18rem;
    letter-spacing: 0.5px;
    position: absolute;
    top: 50px;
    left: 80px;
    line-height: 35px;
  }
  .titleP {
    color: #3d3839;
    font-weight: 600;
    max-width: 18rem;
    letter-spacing: 0.5px;
    position: absolute;
    top: 60px;
    left: 80px;
    line-height: normal;
  }
}

/* large screens */
@media (min-width: 1536px) {
}

.walletCard {
    background: #ffffff;
    border-radius: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
  }
  
  .firstHalf {
    border-right: none;
    padding: 20px;
    flex: 1;
  }
  
  .secondHalf {
    padding: 20px;
    flex: 1;
  }
  
  .cardTitle {
    color: rgba(61, 56, 57, 0.7);
    font-weight: 500;
  }
  
  .accountDetailsDiv {
    display: block;
  }
  
  .accountDetailsIcon {
    margin-right: 20px;
  }
  
  .accountDiv {
    background: #fef6ee;
    border: 0.2px solid #ececec;
    border-radius: 15px;
    padding: 20px;
    display: flex;
  }
  
  .accountInfo {
    display: flex;
    align-items: center;
  }
  
  .accountIcon {
    background: #fcfcfc;
    border-radius: 10px;
    width: 58px;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }
  
  .accountNm {
    color: #3d3839;
    font-family: 'BRFirma600';
    max-width: 10rem;
  }
  
  .accountNo {
    color: rgba(61, 56, 57, 0.7);
    font-family: 'BRFirma500';
    font-size: 14px;
  }
  
  .bankTitle {
    color: rgba(61, 56, 57, 0.7);
    font-family: 'BRFirma500';
    font-size: 14px;
  }
  
  .secondHalf {
    padding: 20px;
    flex: 1;
  }
  
  .info_title {
    color: rgba(61, 56, 57, 0.7);
    font-weight: 600;
    font-size: 15px;
  }
  
  .info_subtitle {
    color: rgba(61, 56, 57, 0.7);
    font-family: 'BRFirma400';
    font-size: 14px;
  }
  
  .pending_badge {
    color: #ffd66b;
    background: rgba(255, 214, 107, 0.1);
    border-radius: 100px;
    padding: 5px 20px;
  }
  
  .success_badge {
    color: #9cc031;
    background: rgba(156, 192, 49, 0.1);
    border-radius: 100px;
    padding: 5px 20px;
  }
  
  .failed_badge {
    color: #ff8f6b;
    background: rgba(255, 143, 107, 0.1);
    border-radius: 100px;
    padding: 5px 20px;
  }
  
  .emptyWalletCard {
    min-height: 500px;
    background: #ffffff;
    border-radius: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .no_widthdrawal {
    font-family: 'Montserrat';
    font-weight: 500;
    /* font-size: 18px; */
    color: rgba(0, 0, 0, 0.6);
  }
  
  .add_new_acct_p {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    color: #f47d20;
    cursor: pointer;
  }
  
  .bank_icon {
    text-align: center;
  }
  
  .add_new_acct_link {
    text-decoration: none;
    font-weight: 400;
    font-size: 16px;
    color: #f47d20;
  }
  
  .add_new_acct_link:hover {
    color: #f47d20;
    text-decoration: none;
  }
  
  .accountCard {
    background: #ffffff;
    border-radius: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
  }
  
  .account_and_radio_div {
    display: flex;
  }
  
  .options_div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
  
  div.options > label > input {
    visibility: hidden;
  }
  
  div.options > label > div {
    display: inline-block;
    padding: 0px;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background: none;
    border: 2px solid rgba(61, 56, 57, 0.7);
    cursor: pointer;
  }
  
  div.options > label > input:checked + div {
    background: url('../../../assets/icons/check-circle\(green\).svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 25px 25px;
    border: none;
  }
  
  .firstHalf {
    border-right: none;
    padding: 20px;
    flex: 1;
  }
  
  .Select_acct_p {
    font-weight: 500;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.6);
  }
  
  .noAccount_Div {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 300px;
    justify-content: center;
  }
  
  .noaccount_title {
    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;
    text-align: center;
  }
  .contentDiv {
    display: flex;
  }
  
  .balanceImg {
    background: rgba(66, 122, 10, 0.1);
    border-radius: 9.83947px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }
  
  .contentImg {
    background: rgba(244, 125, 32, 0.1);
    border-radius: 9.83947px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }
  
  .title {
    color: rgba(61, 56, 57, 0.6);
    font-family: 'BRFirma400';
    font-size: 14px;
    margin-bottom: 0px;
  }
  
  .balance {
    color: #3d3839;
    font-family: 'BRFirma400';
    font-weight: 400;
  }
  
  .withdrawCard {
    background: #ffffff;
    box-shadow: 0px 111.167px 88.9337px rgba(229, 229, 245, 0.25),
      0px 72.0528px 52.0839px rgba(229, 229, 245, 0.189815),
      0px 42.82px 28.327px rgba(229, 229, 245, 0.151852),
      0px 22.2334px 14.4517px rgba(229, 229, 245, 0.125),
      0px 9.05807px 7.24645px rgba(229, 229, 245, 0.0981481),
      0px 2.05865px 3.49971px rgba(229, 229, 245, 0.0601852);
    border-radius: 13.3401px;
    padding: 20px;
  }
  
  .withdrawImgDiv {
    filter: drop-shadow(0px 90.5669px 72.4535px rgba(220, 129, 43, 0.25))
      drop-shadow(0px 58.7008px 42.4323px rgba(220, 129, 43, 0.189815))
      drop-shadow(0px 34.885px 23.0778px rgba(220, 129, 43, 0.151852))
      drop-shadow(0px 18.1134px 11.7737px rgba(220, 129, 43, 0.125))
      drop-shadow(0px 7.37952px 5.90362px rgba(220, 129, 43, 0.0981481))
      drop-shadow(0px 1.67716px 2.85118px rgba(220, 129, 43, 0.0601852));
  }
  
  .duration {
    color: rgba(61, 56, 57, 0.6);
    font-family: 'BRFirma400';
    font-size: 12px;
    font-weight: 300;
  }
  
  .funds_Div {
    background: #ffffff;
    border: 1px solid rgba(244, 125, 32, 0.5);
    box-shadow: 0px 111.167px 88.9337px rgba(229, 229, 245, 0.25),
      0px 72.0528px 52.0839px rgba(229, 229, 245, 0.189815),
      0px 42.82px 28.327px rgba(229, 229, 245, 0.151852),
      0px 22.2334px 14.4517px rgba(229, 229, 245, 0.125),
      0px 9.05807px 7.24645px rgba(229, 229, 245, 0.0981481),
      0px 2.05865px 3.49971px rgba(229, 229, 245, 0.0601852);
    border-radius: 12px;
    padding: 20px;
    display: flex;
    align-items: center;
    text-decoration: none !important;
  }
  
  .fundsImg {
    background: #fff;
    border: 5px solid #9cc031;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
  }
  
  .fundsText {
    margin-bottom: 0px;
    font-family: 'BRFirma500';
    color: rgba(61, 56, 57, 0.8);
  }
  
  .transactTable {
    background: #fff;
    border-radius: 12px;
    padding: 10px;
  }
  
  .filterCard {
    background: #ffffff;
    border-radius: 10px;
    padding: 20px;
  }
  
  .input_container label {
    color: rgba(61, 56, 57, 0.8);
    font-size: 14px;
    font-family: 'BRFirma500';
  }
  
  .filter_input_style {
    -webkit-appearance: none;
    appearance: none;
    background: #fbfbfb;
    border: 1px solid rgba(244, 125, 32, 0.1);
    border-radius: 6px;
    color: rgba(61, 56, 57, 0.8);
    font-size: 14px;
    padding: 15px 20px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;
    font-family: 'BRFirma500';
  }
  
  .filter_input_style:focus {
    background: white;
    box-shadow: none;
    outline: 0 none;
    border: 1px solid rgba(244, 125, 32, 0.1);
  }
  
  .filter_input_style::placeholder {
    font-size: 14px;
    color: rgba(61, 56, 57, 0.8);
    opacity: 0.5;
  }
  
  .btn_filter {
    color: #fff;
    border-radius: 5px;
    background: #3d3839;
    padding: 14px 30px;
    transition: all 0.15s ease-in-out;
    width: 100%;
    font-weight: 300;
  }
  
  .btn_filter:hover {
    color: #fff;
    background: rgba(61, 56, 57, 0.9);
  }
  
  .noaccount_subtitle {
    color: rgba(61, 56, 57, 0.7);
    font-family: 'BRFirma400';
    text-align: center;
    font-size: 14px;
  }

  .main_accountDiv {
    width: 100%;
  }
  
  .accountDiv {
    background: #fef6ee;
    border: 0.2px solid #ececec;
    border-radius: 15px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .accountInfo {
    display: flex;
  }
  
  .accountIcon {
    background: #fcfcfc;
    border-radius: 10px;
    width: 58px;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }
  
  .accountNm {
    color: #3d3839;
    font-family: 'BRFirma600';
    max-width: 10rem;
  }
  
  .accountNo {
    color: rgba(61, 56, 57, 0.7);
    font-family: 'BRFirma500';
    font-size: 14px;
  }
  
  .bankTitle {
    color: rgba(61, 56, 57, 0.7);
    font-family: 'BRFirma500';
    font-size: 14px;
  }
  
  .deleteIcon {
    cursor: pointer;
  }
  
  .secondHalf {
    padding: 20px;
    flex: 1;
  }
  
  .wallet_balance {
    font-family: 'Montserrat';
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);
  }
  
  .wallet_balance_div {
   text-align: right;
  }
  
  .wallet_balance span {
    color: #9cc031;
  }
  
  .input_container label {
    color: rgba(61, 56, 57, 0.8);
    font-size: 14px;
    font-family: 'BRFirma500';
  }
  
  .input_style {
    -webkit-appearance: none;
    appearance: none;
    background: rgba(61, 56, 57, 0.02);
    border: 1px solid rgba(244, 125, 32, 0.1);
    border-radius: 6px;
    color: rgba(61, 56, 57, 0.8);
    font-size: 14px;
    padding: 15px 20px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;
    font-family: 'BRFirma500';
  }
  
  .input_style:disabled {
    -webkit-appearance: none;
    appearance: none;
    color: rgba(61, 56, 57, 0.8);
    opacity: 1;
  }
  
  .input_style:focus {
    background: white;
    box-shadow: none;
    outline: 0 none;
    border: 1px solid rgba(244, 125, 32, 0.1);
  }
  
  .input_style::placeholder {
    font-size: 14px;
    color: rgba(61, 56, 57, 0.8);
    opacity: 0.5;
  }
  
  .btn_add {
    color: #fff;
    border-radius: 6px;
    background: #3d3839;
    padding: 14px 30px;
    transition: all 0.15s ease-in-out;
    width: 100%;
    font-weight: 300;
  }
  
  .btn_add:hover {
    color: #fff;
    background: rgba(61, 56, 57, 0.9);
  }
  
  /* MEDIA QUERY FOR  TABLETS  */
  @media (min-width: 768px) {

    .main_accountDiv {
      
    }

    .options_div {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
    }
  
    .Select_acct_p {
      font-weight: 500;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.6);
    }
  }
  
  /* MEDIA QUERY FOR LARGE SCREENS AND HIGHER THAN TABLETS  */
  @media (min-width: 1024px) {
    .walletCard {
      background: #ffffff;
      border-radius: 10px;
      padding-top: 30px;
      padding-bottom: 30px;
      display: flex;
      flex-direction: row;
    }
  
    .firstHalf {
      border-right: 1px solid #ececec;
      padding: 40px;
      /* flex: 1; */
    }
  
    .secondHalf {
      padding: 40px;
      flex: 1;
    }
    .accountDetailsDiv {
      display: flex;
      align-items: center;
    }
  
    .accountDiv {
      background: #fef6ee;
      border: 0.2px solid #ececec;
      border-radius: 15px;
      padding: 20px;
      display: flex;
      flex-direction: row;
      min-width: 400px;
    }
  
    .accountNm {
      color: #3d3839;
      font-family: 'BRFirma600';
      max-width: 16rem;
    }
  
    .accountCard {
      background: #ffffff;
      border-radius: 10px;
      padding-top: 30px;
      padding-bottom: 30px;
      display: flex;
      flex-direction: row;
    }
  
    .firstHalf {
      border-right: 1px solid #ececec;
      padding: 40px;
      flex: 1;
    }
  
    .secondHalf {
      padding: 40px;
      flex: 1;
    }
  
    .noAccount_Div {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 480px;
      justify-content: center;
    }
  
    .accountDiv {
      background: #fef6ee;
      border: 0.2px solid #ececec;
      border-radius: 15px;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-direction: row;
    }
  
    .accountNm {
      color: #3d3839;
      font-family: 'BRFirma600';
      max-width: 16rem;
    }
  }
  
  /* large screens */
  @media (min-width: 1536px) {
  }
  
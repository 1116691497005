
.summarytitle_div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.walletCard{
    background: #FFFFFF;
    box-shadow: 0px 111.167px 88.9337px rgba(229, 229, 245, 0.25), 0px 72.0528px 52.0839px rgba(229, 229, 245, 0.189815), 0px 42.82px 28.327px rgba(229, 229, 245, 0.151852), 0px 22.2334px 14.4517px rgba(229, 229, 245, 0.125), 0px 9.05807px 7.24645px rgba(229, 229, 245, 0.0981481), 0px 2.05865px 3.49971px rgba(229, 229, 245, 0.0601852);
    border-radius: 13.3401px;
    padding: 20px;
}

.noBgCard{

}

.contentDiv{
    display: flex;
}

.withdrawImgDiv{
    filter: drop-shadow(0px 90.5669px 72.4535px rgba(220, 129, 43, 0.25)) drop-shadow(0px 58.7008px 42.4323px rgba(220, 129, 43, 0.189815)) drop-shadow(0px 34.885px 23.0778px rgba(220, 129, 43, 0.151852)) drop-shadow(0px 18.1134px 11.7737px rgba(220, 129, 43, 0.125)) drop-shadow(0px 7.37952px 5.90362px rgba(220, 129, 43, 0.0981481)) drop-shadow(0px 1.67716px 2.85118px rgba(220, 129, 43, 0.0601852));
}

.incomeImgDiv{
    filter: drop-shadow(0px 90.5669px 72.4535px rgba(74, 43, 220, 0.25)) drop-shadow(0px 58.7008px 42.4323px rgba(74, 43, 220, 0.189815)) drop-shadow(0px 34.885px 23.0778px rgba(74, 43, 220, 0.151852)) drop-shadow(0px 18.1134px 11.7737px rgba(74, 43, 220, 0.125)) drop-shadow(0px 7.37952px 5.90362px rgba(74, 43, 220, 0.0981481)) drop-shadow(0px 1.67716px 2.85118px rgba(74, 43, 220, 0.0601852));
}

.contentImg{
    background: rgba(244, 125, 32, 0.1);
    border-radius: 9.83947px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}

.balanceImg{
    background: rgba(66, 122, 10, 0.1);
    border-radius: 9.83947px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;  
}

.title{
    color: rgba(61, 56, 57, 0.6);
    font-family: 'BRFirma400';
    font-size: 14px;
    margin-bottom: 0px;
}

.balance{
    color: #3D3839;
    font-family: 'BRFirma400';
    font-weight: 400;
}

.duration{
    color: rgba(61, 56, 57, 0.6);
    font-family: 'BRFirma400';
    font-size: 12px;
    font-weight: 300;
}

.latest_div{
    display: flex;
    border-bottom: 1px solid #ECECEC;
    justify-content: space-between;
    padding: 0px 0px 8px 0px;
}

.latest_card{
    display: flex;
    align-items: center;
}

.latest_cardImg{
    background: rgba(61, 56, 57, 0.1);
    /* opacity: 0.1; */
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    margin-right: 20px;
}

.latestcard_title{
    color: #343434;
    font-family: 'BRFirma400';
}

.latestcard_subtitle{
    color: #989898;
    font-family: 'BRFirma400';
    font-size: 12px;
}

.latestcard_amt{
    color: rgba(66, 122, 10, 0.8);
    font-family: 'BRFirma400';

}

.tradenow_card{
    background: rgba(254, 246, 238, 1);
    border-radius: 12px;
    padding: 50px 25px;
    line-height: 21px;
    filter: drop-shadow(0px 30px 70px rgba(61, 56, 57, 0.25));
}

.trade_title{
    color: rgba(0, 0, 0, 0.6);
    font-weight: 600;
}

.trade_subtitle{
    font-family: 'BRFirma400';
    color: rgba(61, 56, 57, 0.7);
    letter-spacing: 0.428571px;
    font-size: 14px;
}

.btn_trade{
    color: #fff;
    border-radius: 5px;
    background: #3D3839;
    padding: 14px 30px;
    transition: all 0.15s ease-in-out;
    width: 100%;
    font-weight: 300;
}

.btn_trade:hover{
    color: #fff;
    background: rgba(61, 56, 57, 0.9);
}

.pendingTable{
    background: #fff;
    border-radius: 12px;
    padding: 10px;
}

/* MEDIA QUERY FOR  TABLETS  */
@media (min-width: 768px) {

}



/* MEDIA QUERY FOR LARGE SCREENS AND HIGHER THAN TABLETS  */
@media (min-width: 1024px) {

}


/* large screens */
@media (min-width: 1536px) {
    
}
.Card{
    background: #FFFFFF;
    border-radius: 10px;
    padding: 15px;
}

.notify_card{
    background: #ECECEC;
    border: 0.2px solid #9CC031;
    border-radius: 10px;
    padding: 25px 25px;
    transition: all .15s ease-in-out;
    cursor: pointer;
    min-height: auto;
}

.notify_title_div{
    display: flex;
    flex-direction: column;
}

.title{
    color: #3D3839;
    font-family: 'BRFirma500';
    margin-bottom: 0px;
}

.date{
    margin-bottom: 0px;
     color: #3D3839;
    opacity: 0.5; 
    font-family: 'BRFirma400';
    font-size: 14px;
}

.content{
    font-family: 'BRFirma500';
    color: rgba(61, 56, 57, 0.7);
    line-height: 26px;
    margin-bottom: 0px;
    font-size: 15px;
}


.no_notifications{
    display: flex;
    flex-direction: column;
    min-height: 500px;
    justify-content: center;
    align-items: center;
    
}

.pagination_div{
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
  }


/* MEDIA QUERY FOR  TABLETS  */
@media (min-width: 768px) {

}


/* MEDIA QUERY FOR LARGE SCREENS AND HIGHER THAN TABLETS  */
@media (min-width: 1024px) {
    .notify_card{
        min-height: 150px;
    }

    .Card{
        background: #FFFFFF;
        border-radius: 10px;
        padding: 30px;
    }  

    .notify_title_div{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

}


/* large screens */
@media (min-width: 1536px) {

}
.rates_section{
    padding: 60px 0px;
}

.ratesBG{
    background: #FBF7F4;
}

.titleDiv{
    position: relative;
}

.titleDiv img{
    width: 500px;
}

.title{
    color: #3D3839;
    font-weight: 600;
    max-width: 18rem;
    letter-spacing: 0.5px;
    position: absolute;
    top: 30px;
    left: 60px;
    line-height: normal;
}

.calculator_div{
 max-width: 30rem;
 margin: auto;   
}


.input_container label {
    color: rgba(61, 56, 57, 0.8);
    font-size: 14px;
    font-family: 'BRFirma500';
  }
  
  .input_style {
    -webkit-appearance: none;
    appearance: none;
    background: #FBF7F4;
    border: 1px solid rgba(244, 125, 32, 0.6);
    border-radius: 6px;
    color: rgba(61, 56, 57, 0.8);
    font-size: 14px;
    padding: 15px 20px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;
    font-family: 'BRFirma500';
  }
  
  .input_style:focus {
    background: white;
    box-shadow: none;
    outline: 0 none;
    border: 1px solid rgba(244, 125, 32, 0.6);
  }
  
  .input_style::placeholder {
    font-size: 14px;
    color: rgba(61, 56, 57, 0.4);
    opacity: 0.5;
  }

  .amount_unit{
    background: #FBF7F4;
    border: 1px solid rgba(244, 125, 32, 0.6);
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
    height: 100%;
    position: absolute;
    right: 0;
    text-align: center;
    width: 100px; 
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(61, 56, 57, 0.3);
  }

  .result_div{
    background: rgba(254, 246, 238, 0.7);
    border: 1px dashed rgba(61, 56, 57, 0.9);
    border-radius: 4.70588px;
    padding: 25px;
  }

  .result_text{
    color: #3D3839;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0px;
  }

.result_amount{
    color: rgba(61, 56, 57, 0.7);
    font-family: 'BRFirma400';
}

.proceed_div{
    display: flex;
    justify-content: center;
    align-items: center;
}

.proceed_text{
    margin-bottom: 0px;
    color: rgba(244, 125, 32, 0.7);
    font-family: 'BRFirma400';
    font-size: 14px;
    margin-left: 5px;
    text-decoration: none;
}

.proceed_text:hover{
    text-decoration: none;
    color: rgba(244, 125, 32, 0.7);
}

/* MEDIA QUERY FOR  TABLETS  */
@media (min-width: 768px) {

}


/* MEDIA QUERY FOR LARGE SCREENS AND HIGHER THAN TABLETS  */
@media (min-width: 1024px) {
    .title{
        color: #3D3839;
        font-weight: 600;
        max-width: 18rem;
        letter-spacing: 0.5px;
        position: absolute;
        top: 50px;
        left: 80px;
        line-height: 35px;
    }
}


/* large screens */
@media (min-width: 1536px) {
    
}
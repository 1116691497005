.faq_section{
    padding: 60px 0px;
}

.titleDiv{
    position: relative;
}

.titleDiv img{
    width: 500px;
}

.title{
    color: #3D3839;
    font-weight: 600;
    max-width: 18rem;
    letter-spacing: 0.5px;
    position: absolute;
    top: 30px;
    left: 40px;
    line-height: normal;
}

.faq_card{
    max-width: 60rem;
    margin: auto;
}

.faq_card img{
    box-shadow: 0px 5px 16px rgb(8 15 52 / 6%);
    border-radius: 50%;
}

.card{
    background: #FFFFFF;
    border: 2px solid #FDE2CD;
    box-shadow: 0px 8px 25px rgba(209, 177, 153, 0.2);
    border-radius: 10px;
    padding: 20px 25px;
    cursor: pointer;
}

.faq_title{
    margin-bottom: 0px;
    color: #3D3839;
    font-weight: 500;
}

.faq_content{
    margin-bottom: 0px;
    color: rgba(61, 56, 57, 0.7);
    font-family: 'BRFirma400';
    font-size: 14px;
    line-height: 25px;
    /* max-width: 48rem; */
}


/* ------------------MEDIA QUERY SECTION ------------------------- */
/* Media query for tablets and screens higher */
@media (min-width: 768px) {
}


/* sreens higher than tablets */
@media (min-width: 1024px) {
    .title{
        color: #3D3839;
        font-weight: 600;
        max-width: 18rem;
        letter-spacing: 0.5px;
        position: absolute;
        top: 50px;
        left: 80px;
        line-height: 35px;
    }

}



/* large screens */
@media (min-width: 1536px) {
    
}
.accountCard{
    background: #FFFFFF;
    border-radius: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
}


.firstHalf{
    border-right: none;
    padding: 20px;
    flex: 1;
}

.noAccount_Div{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 300px;
    justify-content: center;
}


.noaccount_title{
    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;
    text-align: center;
}

.noaccount_subtitle{
    color: rgba(61, 56, 57, 0.7);
    font-family: 'BRFirma400';
    text-align: center;
    font-size: 14px;
}

.accountDiv{
    background: #FEF6EE;
    border: 0.2px solid #ECECEC;
    border-radius: 15px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.accountInfo{
    display: flex;
}

.accountIcon{
    background: #FCFCFC;
    border-radius: 10px;
    width: 58px;
    height: 58px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}

.accountNm{
    color: #3D3839;
    font-family: 'BRFirma600';
    max-width: 10rem;
}

.accountNo{
    color: rgba(61, 56, 57, 0.7);
    font-family: 'BRFirma500';
    font-size: 14px;
}

.bankTitle{
    color: rgba(61, 56, 57, 0.7);
    font-family: 'BRFirma500';
    font-size: 14px; 
}

.deleteIcon{
    cursor: pointer;
}


.secondHalf{
    padding: 20px;
    flex: 1;
}

.Title{
    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;
}

.input_container label {
    color: rgba(61, 56, 57, 0.8);
    font-size: 14px;
    font-family: 'BRFirma500';
  }
  
  .input_style {
    -webkit-appearance: none;
    appearance: none;
    background: rgba(61, 56, 57, 0.02);
    border: 1px solid rgba(244, 125, 32, 0.1);
    border-radius: 6px;
    color: rgba(61, 56, 57, 0.8);
    font-size: 14px;
    padding: 15px 20px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;
    font-family: 'BRFirma500';
  }
  

  .input_style:disabled {
    -webkit-appearance: none;
    appearance: none;
    color: rgba(61, 56, 57, 0.8);
    opacity: 1;
}

  .input_style:focus {
    background: white;
    box-shadow: none;
    outline: 0 none;
    border: 1px solid rgba(244, 125, 32, 0.1);
  }
  
  .input_style::placeholder {
    font-size: 14px;
    color: rgba(61, 56, 57, 0.8);
    opacity: 0.5;
  }


.btn_add{
    color: #fff;
    border-radius: 6px;
    background: #3D3839;
    padding: 14px 30px;
    transition: all 0.15s ease-in-out;
    width: 100%;
    font-weight: 300;
}

.btn_add:hover{
    color: #fff;
    background: rgba(61, 56, 57, 0.9);
}



/* MEDIA QUERY FOR  TABLETS  */
@media (min-width: 768px) {

}



/* MEDIA QUERY FOR LARGE SCREENS AND HIGHER THAN TABLETS  */
@media (min-width: 1024px) {
    .accountCard{
        background: #FFFFFF;
        border-radius: 10px;
        padding-top: 30px;
        padding-bottom: 30px;
        display: flex;
        flex-direction: row;
    }

    .firstHalf{
        border-right: 1px solid #ECECEC;
        padding: 40px;
        flex: 1;
    }

    .secondHalf{
        padding: 40px;
        flex: 1;
    }

    .noAccount_Div{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 480px;
        justify-content: center;
    }

    .accountDiv{
        background: #FEF6EE;
        border: 0.2px solid #ECECEC;
        border-radius: 15px;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-direction: row;
    }

    .accountNm{
        color: #3D3839;
        font-family: 'BRFirma600';
        max-width: 16rem;
    }
    
   

}


/* large screens */
@media (min-width: 1536px) {
    
}
